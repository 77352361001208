import { NavItemContent } from '../../components/Header';

const navContent: NavItemContent[] = [
  {
    title: 'header.aboutUs',
    link: 'https://www.cncenter.cz/en/about',
  },
  {
    title: 'header.brands',
    link: 'https://www.cncenter.cz/en/titles',
  },
  {
    title: 'header.advertisement',
    link: 'https://www.cncenter.cz/en/advertisement',
    sub: {
      onlyMobile: false,
      items: [
        {
          title: 'header.advertisementSub.inquiryForm',
          link: 'https://www.cncenter.cz/en/advertisement#ad-form',
        },
        {
          title: 'header.advertisementSub.priceLists',
          link: 'https://www.cncenter.cz/en/advertisement#download',
        },
        {
          title: 'header.advertisementSub.mediatypes',
          link: 'https://www.cncenter.cz/en/advertisement#mediatypes',
        },
        {
          title: 'header.advertisementSub.links',
          link: 'https://www.cncenter.cz/en/advertisement#important-links',
        },
        {
          title: 'header.advertisementSub.specifications',
          link: 'https://www.cncenter.cz/en/clanek/2342/technicke-specifikace-formatu-na-cnc',
        },
      ],
    },
  },
  {
    title: 'header.contact',
    link: 'https://www.cncenter.cz/en/contact',
  },
];

export default navContent;
