import { FooterItemContent } from '../../components/Footer';

const footerContent: FooterItemContent[] = [
  {
    title: 'footer.aboutUs.title',
    linkItems: [
      {
        text: 'footer.aboutUs.ourVision',
        linkRoute: 'https://www.cncenter.cz/en/about#our-vision',
      },
      {
        text: 'footer.aboutUs.whoAreWe',
        linkRoute: 'https://www.cncenter.cz/en/about#who-are-we',
      },
      {
        text: 'footer.aboutUs.owners',
        linkRoute: 'https://www.cncenter.cz/en/about#ownership',
      },
      {
        text: 'footer.aboutUs.management',
        linkRoute: 'https://www.cncenter.cz/en/about#management',
      },
      {
        text: 'footer.aboutUs.history',
        linkRoute: 'https://www.cncenter.cz/en/about#history',
      },
      {
        text: 'footer.aboutUs.awards',
        linkRoute: 'https://www.cncenter.cz/en/about#rewards',
      },
      {
        text: 'footer.aboutUs.codeOfEthics',
        linkRoute: 'https://www.cncenter.cz/en/code-of-ethics',
      },
    ],
  },
  {
    title: 'footer.advertisement.title',
    linkItems: [
      {
        text: 'footer.advertisement.hits',
        linkRoute: 'https://www.cncenter.cz/en/advertisement#cross-media-share',
      },
      {
        text: 'footer.advertisement.mediatypes',
        linkRoute: 'https://www.cncenter.cz/en/advertisement#mediatypes',
      },
      {
        text: 'footer.advertisement.whyUs',
        linkRoute: 'https://www.cncenter.cz/en/advertisement#why-us',
      },
      {
        text: 'footer.advertisement.cooperation',
        linkRoute: 'https://www.cncenter.cz/en/advertisement#cooperation',
      },
      {
        text: 'footer.advertisement.links',
        linkRoute: 'https://www.cncenter.cz/en/advertisement#important-links',
      },
      {
        text: 'footer.advertisement.downloads',
        linkRoute: 'https://www.cncenter.cz/en/advertisement#download',
      },
    ],
  },
];

export default footerContent;
