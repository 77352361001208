const LinkedIn = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <path
        d='M4.5 0c-2.2 0-4 1.8-4 3.9 0 2.2 1.8 3.9 4 3.9S8.4 6 8.4 3.9C8.4 1.8 6.6 0 4.5 0zm0 6c-1.2 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1C6.5 5.1 5.6 6 4.5 6zm3 4h-6c-.5 0-.9.4-.9.9V31c0 .5.4.9.9.9h6c.5 0 .9-.4.9-.9V11c0-.5-.4-1-.9-1zm-1 20.1H2.4V11.9h4.1v18.2zm11.9-19.2c1.3-.6 2.7-1 4.1-1 4.9 0 9 4 9 9v12.2c0 .5-.4.9-.9.9h-6c-.5 0-.9-.4-.9-.9V20c0-1.4-1.2-2.6-2.6-2.6s-2.6 1.2-2.6 2.6v11c0 .5-.4.9-.9.9h-6c-.5 0-.9-.4-.9-.9V11c0-.5.4-.9.9-.9h6c.4-.1.8.3.8.8zm7.1 19.2h4.1V18.9c0-3.9-3.2-7.1-7.1-7.1-1.6 0-3.2.5-4.4 1.6-.3.2-.7.3-1 .1-.3-.2-.5-.5-.5-.8V12h-4.2v18.2h4.2V20c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v10.1z'
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#00478e' }}
      ></path>
    </svg>
  );
};

export default LinkedIn;
