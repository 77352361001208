import { NavItemContent } from '../../components/Header';

const navContent: NavItemContent[] = [
  {
    title: 'header.aboutUs',
    link: 'https://www.cncenter.cz/o-nas',
  },
  {
    title: 'header.brands',
    link: 'https://www.cncenter.cz/tituly',
  },
  {
    title: 'header.advertisement',
    link: 'https://www.cncenter.cz/inzerce',
    sub: {
      onlyMobile: false,
      items: [
        {
          title: 'header.advertisementSub.inquiryForm',
          link: 'https://www.cncenter.cz/inzerce#ad-form',
        },
        {
          title: 'header.advertisementSub.priceLists',
          link: 'https://www.cncenter.cz/inzerce#ke-stazeni',
        },
        {
          title: 'header.advertisementSub.mediatypes',
          link: 'https://www.cncenter.cz/inzerce#mediatypy',
        },
        {
          title: 'header.advertisementSub.links',
          link: 'https://www.cncenter.cz/inzerce#dulezite-odkazy',
        },
        {
          title: 'header.advertisementSub.specifications',
          link: 'https://www.cncenter.cz/clanek/2342/technicke-specifikace-formatu-na-cnc',
        },
      ],
    },
  },
  {
    title: 'header.career',
    link: 'https://www.cncenter.cz/kariera',
  },
  {
    title: 'header.blog',
    link: 'https://www.cncenter.cz/magazin',
    sub: {
      onlyMobile: true,
      items: [
        {
          title: 'header.blogSub.articles',
          link: 'https://www.cncenter.cz/magazin',
        },
        {
          title: 'header.blogSub.cooperation',
          link: 'https://www.cncenter.cz/kategorie/spoluprace',
        },
        {
          title: 'header.blogSub.offers',
          link: 'https://www.cncenter.cz/kategorie/prave-nabizime',
        },
        {
          title: 'header.blogSub.technology',
          link: 'https://www.cncenter.cz/kategorie/technologie',
        },
        {
          title: 'header.blogSub.news',
          link: 'https://www.cncenter.cz/kategorie/novinky-o-titulech',
        },
        {
          title: 'header.blogSub.dataStatistics',
          link: 'https://www.cncenter.cz/kategorie/data-a-statistiky',
        },
        {
          title: 'header.blogSub.people',
          link: 'https://www.cncenter.cz/kategorie/lide-z-cnc',
        },
        {
          title: 'header.blogSub.pressReleases',
          link: 'https://www.cncenter.cz/kategorie/tiskove-zpravy',
        },
      ],
    },
  },
  {
    title: 'header.contact',
    link: 'https://www.cncenter.cz/kontakt',
  },
];

export default navContent;
