const ArrowRight = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.586 12.728L8 14.143l5.657-5.657 1.414-1.414-1.414-1.415L8 0 6.586 1.415l5.657 5.657-5.657 5.656z'
        fill='#00478E'
      ></path>
    </svg>
  );
};

export default ArrowRight;
