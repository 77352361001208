import { styled } from 'styled-components';

const PageContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 75.125rem;
  margin-inline: auto;
  padding-inline: 1rem;
  align-items: center;
  padding-bottom: 50px;

  @media only screen and (min-width: 1080px) {
    width: 90%;
  }

  @media only screen and (min-width: 640px) {
  }
`;

export default PageContainer;
