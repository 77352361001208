import { styled } from 'styled-components';

export const Label = styled.label`
  box-sizing: border-box;
  text-align: left;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: Gotham, sans-serif;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: -0.05em;
  font-size: calc(0.15625vw + 13.375px);
  color: #333;
  margin-bottom: 12px;

  @media (min-width: 400px) {
    font-size: calc(0.15625vw + 13.375px);
  }

  @media (min-width: 1680px) {
    font-size: 16px;
  }
`;
