

export default function CNCLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="97" height="39" viewBox="0 0 97 39" fill="none">
	        <path d="M27.625 11.2173L29.0937 11.2052C29.0937 11.2052 40.5568 20.7351 46.3122 25.4759C46.3481 25.4517 46.3958 25.4396 46.4317 25.4155C46.4317 20.6989 46.4317 11.2294 46.4317 11.2294H52.9035V38.3075L51.4109 38.368C51.4109 38.368 39.8762 28.4147 34.0133 23.3474C34.0133 28.4268 34.0133 38.2833 34.0133 38.2833L27.6608 38.3317L27.625 11.2173Z" fill="#00478E"></path>
	        <path d="M20.3772 30.6887L24.055 35.6592C24.055 35.6592 23.6848 36.0462 23.4938 36.1913C21.7385 37.57 19.7324 38.332 17.5712 38.7311C16.1144 38.9971 14.6457 39.0576 13.177 38.9487C9.57089 38.6827 6.37078 37.437 3.80353 34.7764C2.01242 32.9139 0.901931 30.6645 0.364599 28.1369C0.0899625 26.8549 -0.0533261 25.5609 0.0183182 24.2426C0.197429 20.4936 1.41538 17.1678 4.10204 14.495C5.97674 12.6447 8.23353 11.532 10.7769 10.9636C12.4605 10.5766 14.1681 10.504 15.8756 10.625C18.3234 10.7943 20.6399 11.4474 22.7296 12.8019C23.1953 13.1042 23.6609 13.4187 24.0669 13.9024C22.849 15.5593 20.3534 18.9214 20.3534 18.9214C20.3534 18.9214 20.1145 18.752 19.9832 18.6795C18.5384 17.7603 16.9861 17.0831 15.2786 16.9259C11.4814 16.5872 7.83949 18.6432 6.96782 22.876C6.65736 24.3636 6.68124 25.8874 7.12305 27.3508C7.9589 30.0719 9.76194 31.8134 12.5083 32.4423C15.3263 33.0953 17.9533 32.5753 20.2698 30.7612C20.2698 30.737 20.3772 30.6887 20.3772 30.6887Z" fill="#00478E"></path>
	        <path d="M77.5844 30.6887C78.8263 32.3577 80.0442 34.0145 81.2741 35.6714C80.689 36.3002 80.0323 36.7356 79.3397 37.1347C77.6322 38.1022 75.7933 38.6223 73.8589 38.8641C72.8081 38.9972 71.7574 39.0093 70.7066 38.9609C67.4826 38.8037 64.5452 37.8241 62.0376 35.7197C59.7331 33.7726 58.3121 31.2692 57.6196 28.3183C57.2613 26.7945 57.1539 25.2465 57.2733 23.7106C57.6912 18.5223 60.0196 14.5434 64.6765 12.1368C66.2049 11.3386 67.8527 10.9153 69.5722 10.7097C70.6827 10.5767 71.7932 10.5525 72.9037 10.625C75.6262 10.7944 78.1815 11.5079 80.4263 13.1527C80.7129 13.3582 81.298 13.8662 81.298 13.8662L77.5605 18.9214C77.5605 18.9214 77.2143 18.6916 77.059 18.5949C75.6261 17.7241 74.1216 17.0469 72.4499 16.9138C68.7722 16.6115 65.2497 18.5102 64.2347 22.5496C63.7929 24.3153 63.8526 26.0689 64.4616 27.7741C65.3452 30.2533 67.0885 31.8255 69.608 32.4302C72.1036 33.047 74.4918 32.6842 76.7128 31.3176C76.9874 31.1362 77.2501 30.9306 77.5844 30.6887Z" fill="#00478E"></path>
	        <path d="M88.4172 5.1585C84.8111 5.1585 77.778 5.1585 77.778 5.1585L77.7422 0.0670006C77.7422 0.0670006 95.4025 -0.0660316 96.2264 0.0428129C93.898 5.27944 92.7278 10.77 92.5726 16.5146C92.5726 16.6114 92.5606 16.817 92.5606 16.817H86.9007C86.9007 16.817 86.8888 16.5509 86.8768 16.4179C86.8171 14.5796 87.0201 12.7534 87.2589 10.9514C87.4978 9.01644 87.8679 7.10561 88.4172 5.1585Z" fill="#B4D333"></path>
        </svg>
    )
}