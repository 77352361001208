import { styled } from 'styled-components';

export default function LoadingIcon() {
  return (
    <Rotation>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='200px'
        height='200px'
        viewBox='0 0 100 100'
      >
        <circle
          cx='50'
          cy='50'
          fill='none'
          stroke='#fff'
          stroke-width='16'
          r='42'
          stroke-dasharray='164.93361431346415 56.97787143782138'
        ></circle>
      </svg>
    </Rotation>
  );
}

const Rotation = styled.div`
  @keyframes anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: anim 1s cubic-bezier(0.5, 0.1, 0.5, 0.9) infinite;
  }
`;
