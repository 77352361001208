const Facebook = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <path
        d='M4.7 32h10.4V20.6h-3.8v-3.8h3.8v-4.7c0-2.6 2.1-4.7 4.7-4.7h4.7v3.8h-3.8c-1 0-1.9.8-1.9 1.9v3.8h5.5l-.6 3.8h-4.8V32h8.4c2.6 0 4.7-2.1 4.7-4.7V4.7C32 2.1 29.9 0 27.3 0H4.7C2.1 0 0 2.1 0 4.7v22.6C0 29.9 2.1 32 4.7 32zM1.9 4.7c0-1.6 1.3-2.8 2.8-2.8h22.6c1.6 0 2.8 1.3 2.8 2.8v22.6c0 1.6-1.3 2.8-2.8 2.8h-6.6v-7.6h2.8c1 0 1.8-.7 2-1.7l.6-3.5c.2-1.2-.7-2.3-2-2.3h-3.4v-1.9h3.6c1.1 0 2-.9 2-2V7.6c0-1.1-.9-2-2-2h-4.6c-3.6 0-6.6 2.9-6.6 6.6V15h-1.8c-1.1 0-2 .9-2 2v3.5c0 1.1.9 2 2 2h1.8v7.6H4.7c-1.6 0-2.8-1.3-2.8-2.8V4.7z'
        style={{ fill: '#00478e' }}
      ></path>
    </svg>
  );
};

export default Facebook;
