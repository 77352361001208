import { styled } from 'styled-components';

export const ErrorText = styled.p`
text-align: left;
box-sizing: border-box;
margin: 0;
padding: 0;
border: 0;
vertical-align: baseline;
font-weight: 500;
font-size: 13px;
color: #ec5947;
transform-origin: left;
margin-top: 10px;
`;