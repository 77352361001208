import { styled } from 'styled-components';
import LinkedIn from './icons/LinkedIn';
import Facebook from './icons/Facebook';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import footerContentCz from '../lang/cz/footer';
import footerContentEn from '../lang/en/footer';
import CNCLogo from './icons/CNCLogo';

export interface FooterItemContent {
  title: string;
  linkItems: { text: string; linkRoute: string }[];
}

export default function Footer() {
  const { t, i18n } = useTranslation();

  const content = useMemo(() => {
    if (i18n.language === 'cz') return footerContentCz;
    else return footerContentEn;
  }, [i18n.language]);

  return (
    <FooterContainer>
      <FooterContentWrap>
        <FooterTop>
          <FooterLists>
            {content.map((e) => (
              <FooterColumn>
                <FooterTitle>{t(e.title)}</FooterTitle>
                <FooterList>
                  {e.linkItems.map((listItem) => (
                    <FooterListItem>
                      <FooterListItemText href={t(listItem.linkRoute)}>
                        {t(listItem.text)}
                      </FooterListItemText>
                    </FooterListItem>
                  ))}
                </FooterList>
              </FooterColumn>
            ))}
          </FooterLists>
          <FooterContact>
            <FooterSocials>
              <FooterSocialsLink
                href='https://www.linkedin.com/company/czech-news-center-cz/'
                target='_blank'
              >
                <div>
                  <LinkedIn />
                </div>
              </FooterSocialsLink>
              <FooterSocialsLink
                href='https://www.facebook.com/CzechNewsCenter'
                target='_blank'
              >
                <div>
                  <Facebook />
                </div>
              </FooterSocialsLink>
            </FooterSocials>
            <FooterInformation>
              <FooterPart>
                <strong>CZECH NEWS CENTER a.s.</strong>
                <address>
                  Komunardů 1584/42
                  <br />
                  170 00 Praha 7<br />
                  Česká republika
                </address>
              </FooterPart>
              <FooterPart>
                <a href='tel: +420 225 977 111'>+420 225 977 111</a>
                <a href='mailto: info@cncenter.cz'>info@cncenter.cz</a>
              </FooterPart>
              <FooterPart>
                <p>IČO: 02346826</p>
                <p>DIČ: CZ02346826</p>
              </FooterPart>
            </FooterInformation>
          </FooterContact>
        </FooterTop>

        <FooterBottom>
          <FooterLogo>
            <CNCLogo />
          </FooterLogo>
          <FooterLegals>
            <FooterCopyright>{t('footer.copyright')}</FooterCopyright>
            <FooterLegalsLinks>
              <a href={t('footer.copyrightLinksHrefs.copyrights')}>
                {t('footer.copyrightLinks.copyrights')}
              </a>
              &nbsp;|&nbsp;
              <a href={t('footer.copyrightLinksHrefs.cookies')}>
                {t('footer.copyrightLinks.cookies')}
              </a>
              &nbsp;|&nbsp;
              <a href={t('footer.copyrightLinksHrefs.privacy')}>
                {t('footer.copyrightLinks.privacy')}
              </a>
              &nbsp;|&nbsp;
              <a href={t('footer.copyrightLinksHrefs.conditions')}>
                {t('footer.copyrightLinks.conditions')}
              </a>
              <br />
              <a href={t('footer.copyrightLinksHrefs.personalData')}>
                {t('footer.copyrightLinks.personalData')}
              </a>
              &nbsp;|&nbsp;
              <a href={t('footer.copyrightLinksHrefs.usageTerms')}>
                {t('footer.copyrightLinks.usageTerms')}
              </a>
              &nbsp;|&nbsp;
              <a href={t('footer.copyrightLinksHrefs.contactPoint')}>
                {t('footer.copyrightLinks.contactPoint')}
              </a>
              &nbsp;|&nbsp;
              <a href={t('footer.copyrightLinksHrefs.orderForm')}>
                {t('footer.copyrightLinks.orderForm')}
              </a>
            </FooterLegalsLinks>
            <FooterCopyright>
              {t('footer.creator.made')}&nbsp;❤️&nbsp;
              {t('footer.creator.by')}&nbsp;
              <a
                href='https://www.yolkstudio.com'
                rel='noopener noreferrer nofollow'
                target='_blank'
              >
                Yolk Studio
              </a>
            </FooterCopyright>
          </FooterLegals>
        </FooterBottom>
      </FooterContentWrap>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background-color: #00478e;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (min-width: 1280px) {
    padding-top: 63px;
    padding-bottom: 63px;
  }

  @media only screen and (min-width: 1920px) {
    padding-bottom: 93px;
  }
`;

const FooterContentWrap = styled.div`
  max-width: 1124px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

  @media only screen and (min-width: 360px) {
    padding-right: 25px;
    padding-left: 25px;
  }

  @media only screen and (min-width: 768px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media only screen and (min-width: 1080px) {
    width: 90%;
  }
`;

const FooterTop = styled.div`
  @media only screen and (min-width: 1280px) {
    display: flex;
  }
`;

const FooterLists = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  gap: 50px;

  @media only screen and (min-width: 768px) {
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 1280px) {
    gap: 30px;
    flex-grow: 1;
  }

  @media only screen and (min-width: 1680px) {
    gap: 50px;
  }
`;

const FooterColumn = styled.div``;

const FooterTitle = styled.h5`
  line-height: 1.8;

  margin-top: 0;
  margin-bottom: 15px;

  color: #fff;
  font-weight: 600;
  font-size: 16px;
  font-family: Gotham, sans-serif;
  letter-spacing: -0.05em;
`;

const FooterList = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
`;

const FooterListItem = styled.li`
  margin: 0 0 5px 0;
  padding: 0;
`;

const FooterListItemText = styled.a`
  text-decoration: none;
  font-weight: 400;
  line-height: 1.7;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.7);
  text-align: left;

  @media (min-width: 400px) {
    font-size: calc(0.2272727273vw + 11.0909090909px);
  }

  @media (min-width: 1280px) {
    font-size: 14px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const FooterContact = styled.div`
  padding-top: 30px;
  margin-top: 50px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.3);

  @media only screen and (min-width: 560px) {
    display: flex;
  }

  @media only screen and (min-width: 1280px) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    border-left: 1px solid hsla(0, 0%, 100%, 0.3);
  }

  @media only screen and (min-width: 1680px) {
    width: 30%;
  }

  @media only screen and (min-width: 1920px) {
    width: 33%;
  }
`;

const FooterSocials = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media only screen and (min-width: 768px) {
    width: calc(50% + 10px);
  }

  @media only screen and (min-width: 900px) {
    width: calc(25% + 5px);
  }

  @media only screen and (min-width: 1280px) {
    margin-right: 20px;
    margin-left: 20px;
    flex-direction: column;
    justify-content: space-evenly;
  }

  @media only screen and (min-width: 1680px) {
    flex-grow: 1;
  }

  @media only screen and (min-width: 1920px) {
    margin-right: 50px;
  }
`;

const FooterSocialsLink = styled.a`
  transition: opacity 0.3s;

  &:not(:first-child) {
    margin-left: 30px;

    @media only screen and (min-width: 1280px) {
      margin-left: auto;
    }
  }

  @media only screen and (min-width: 560px) {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 1280px) {
    margin-right: auto;
    margin-left: auto;
  }

  > div {
    display: flex;
  }

  svg {
    width: 40px;
    height: 40px;

    path {
      fill: #b2c7dd !important;
    }
  }

  &:hover {
    opacity: 0.8;
  }
`;

const FooterInformation = styled.div`
  margin-top: 30px;

  @media only screen and (min-width: 560px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-left: 75px;
  }

  @media only screen and (min-width: 768px) {
    margin-left: 0;
  }

  @media only screen and (min-width: 1280px) {
    width: auto;
    flex-shrink: 0;
    flex-direction: column;
  }
`;

const FooterPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-family: Gilmer, sans-serif;

  @media only screen and (min-width: 560px) {
    width: 50%;
  }

  &:first-child {
    @media only screen and (min-width: 560px) {
      width: 100%;
    }
  }

  &,
  &:first-child {
    @media only screen and (min-width: 900px) {
      width: auto;
    }
  }

  &:not(:first-child) {
    margin-top: 20px;

    @media only screen and (min-width: 900px) {
      margin-top: 0;
      margin-left: 75px;
    }

    @media only screen and (min-width: 1280px) {
      margin-left: 0;
      margin-top: 30px;
    }
  }

  strong {
    color: #fff;
    font-weight: 700;
    line-height: 1.7;
    font-size: 13px;

    @media (min-width: 1280px) {
      font-size: 13px;
    }

    @media (min-width: 400px) {
      font-size: calc(0vw + 13px);
    }
  }

  address,
  a,
  p {
    margin: 0;

    color: #fff;
    font-weight: 400;
    font-size: 13px;
    font-style: normal;
    line-height: 1.7;
    text-decoration: none;

    @media (min-width: 400px) {
      font-size: calc(0vw + 13px);
    }

    @media (min-width: 1280px) {
      font-size: 13px;
    }
  }

  a:hover {
    text-decoration: underline;
  }
`;

const FooterBottom = styled.div`
  @media only screen and (min-width: 1680px) {
    margin-top: 65px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (min-width: 1920px) {
    margin-top: 95px;
  }
`;

const FooterLogo = styled.div`
  display: flex;

  margin-top: 30px;
  margin-bottom: 30px;

  @media only screen and (min-width: 1680px) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
  }

  svg {
    max-width: 100%;

    path {
      fill: #fff;
    }
  }
`;

const FooterLegals = styled.div``;

const FooterCopyright = styled.p`
  margin: 0;

  color: #fff;
  font-family: Gilmer, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.7;

  @media (min-width: 400px) {
    font-size: calc(0vw + 13px);
  }

  @media (min-width: 1280px) {
    font-size: 13px;
  }

  a {
    color: #fff;
  }
`;

const FooterLegalsLinks = styled.p`
  margin: 0;

  color: hsla(0, 0%, 100%, 0.7);
  font-family: Gotham, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;

  @media (min-width: 400px) {
    font-size: calc(0.2272727273vw + 11.0909090909px);
  }

  @media (min-width: 1280px) {
    font-size: 14px;
  }

  a {
    color: hsla(0, 0%, 100%, 0.7);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
