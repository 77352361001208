import { styled } from 'styled-components';

interface Props {
    
}

const AsterixContainer = styled.span`
    color:#ED0131;
`;

/**
 * The above code defines a React functional component called Asterix that renders an asterisk enclosed
 * in an AsterixContainer component.
 */
const Asterix: React.FC<Props> = () => {
    return <AsterixContainer>*</AsterixContainer>
 };

export default Asterix;