import { FooterItemContent } from '../../components/Footer';

const footerContent: FooterItemContent[] = [
  {
    title: 'footer.aboutUs.title',
    linkItems: [
      {
        text: 'footer.aboutUs.ourVision',
        linkRoute: 'https://www.cncenter.cz/o-nas#nase-vize',
      },
      {
        text: 'footer.aboutUs.whoAreWe',
        linkRoute: 'https://www.cncenter.cz/o-nas#kdo-jsme',
      },
      {
        text: 'footer.aboutUs.owners',
        linkRoute: 'https://www.cncenter.cz/o-nas#vlastnici',
      },
      {
        text: 'footer.aboutUs.management',
        linkRoute: 'https://www.cncenter.cz/o-nas#management',
      },
      {
        text: 'footer.aboutUs.history',
        linkRoute: 'https://www.cncenter.cz/o-nas#historie',
      },
      {
        text: 'footer.aboutUs.awards',
        linkRoute: 'https://www.cncenter.cz/o-nas#oceneni',
      },
      {
        text: 'footer.aboutUs.partnerships',
        linkRoute: 'https://www.cncenter.cz/podporujeme',
      },
      {
        text: 'footer.aboutUs.codeOfEthics',
        linkRoute: 'https://www.cncenter.cz/eticky-kodex',
      },
    ],
  },
  {
    title: 'footer.blog.title',
    linkItems: [
      {
        text: 'footer.blog.partnerships',
        linkRoute: 'https://www.cncenter.cz/kategorie/spoluprace',
      },
      {
        text: 'footer.blog.offers',
        linkRoute: 'https://www.cncenter.cz/kategorie/prave-nabizime',
      },
      {
        text: 'footer.blog.technologies',
        linkRoute: 'https://www.cncenter.cz/kategorie/technologie',
      },
      {
        text: 'footer.blog.news',
        linkRoute: 'https://www.cncenter.cz/kategorie/novinky-o-titulech',
      },
      {
        text: 'footer.blog.dataStatistics',
        linkRoute: 'https://www.cncenter.cz/kategorie/data-a-statistiky',
      },
      {
        text: 'footer.blog.people',
        linkRoute: 'https://www.cncenter.cz/kategorie/lide-z-cnc',
      },
      {
        text: 'footer.blog.pressReleases',
        linkRoute: 'https://www.cncenter.cz/kategorie/tiskove-zpravy',
      },
    ],
  },
  {
    title: 'footer.advertisement.title',
    linkItems: [
      {
        text: 'footer.advertisement.targetAudience',
        linkRoute: 'https://www.cncenter.cz/inzerce#cross-medialni-zasah',
      },
      {
        text: 'footer.advertisement.mediatypes',
        linkRoute: 'https://www.cncenter.cz/inzerce#mediatypy',
      },
      {
        text: 'footer.advertisement.whyUs',
        linkRoute: 'https://www.cncenter.cz/inzerce#proc-my',
      },
      {
        text: 'footer.advertisement.partnership',
        linkRoute: 'https://www.cncenter.cz/inzerce#uspesne-spoluprace',
      },
      {
        text: 'footer.advertisement.links',
        linkRoute: 'https://www.cncenter.cz/inzerce#dulezite-odkazy',
      },
      {
        text: 'footer.advertisement.downloads',
        linkRoute: 'https://www.cncenter.cz/inzerce#ke-stazeni',
      },
    ],
  },
  {
    title: 'footer.career.title',
    linkItems: [
      {
        text: 'footer.career.jobOffers',
        linkRoute: 'https://www.cncenter.cz/kariera#volna-mista',
      },
      {
        text: 'footer.career.benefits',
        linkRoute: 'https://www.cncenter.cz/kariera#benefity',
      },
      {
        text: 'footer.career.locality',
        linkRoute: 'https://www.cncenter.cz/kariera#lokalita',
      },
    ],
  },
];

export default footerContent;
