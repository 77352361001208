import LoadingIcon from '../icons/LoadingIcon';
import MailIcon from '../icons/MailIcon';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.button<{ disabled: boolean }>`
  padding: 13px 16px 13px 23px;
  border: 1px solid #00478e;
  background-color: ${(props) =>
    props.disabled ? 'rgba(0, 71, 142, 0.6)' : '#00478e'};
  justify-content: space-between;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
  transition: border-color 0.2s, background-color 0.2s,
    background-position-x 0.2s;

  &:hover {
    border: 1px solid rgba(0, 71, 142, 0.9);
    background-color: rgba(0, 71, 142, 0.8);
  }

  @media only screen and (min-width: 280px) {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
  }

  @media only screen and (min-width: 900px) {
    gap: 40px;
  }
`;

const TextContainer = styled.span`
  text-transform: uppercase;
  color: #fff;
  font-family: Gotham, sans-serif;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.1em;

  @media (min-width: 640px) {
    font-size: 14px;
  }
`;

const ButtonIconContainer = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;

interface Props {
  loading: boolean;
  disabled: boolean;
}

const Submit: React.FC<Props> = ({ loading, disabled }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer type='submit' disabled={disabled}>
      <TextContainer>
        {loading ? t('form.submitting') : t('form.submit')}
      </TextContainer>
      <ButtonIconContainer>
        {loading ? <LoadingIcon /> : <MailIcon />}
      </ButtonIconContainer>
    </ButtonContainer>
  );
};

export default Submit;
