import styled from 'styled-components';
import CNCLogo from './icons/CNCLogo';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import navContentCz from '../lang/cz/nav';
import navContentEn from '../lang/en/nav';
import ArrowDown from './icons/ArrowDown';
import ArrowRight from './icons/ArrowRight';

const HeaderEl = styled.header`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  z-index: 11;

  background-color: #fff;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 1280px;

  @media only screen and (min-width: 768px) {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  @media only screen and (min-width: 768px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media only screen and (min-width: 1080px) {
    width: 90%;
  }
`;

const LogoImageContainer = styled.a`
  height: 32px;

  cursor: pointer;

  svg {
    width: 120px;
    height: 32px;
  }

  @media only screen and (min-width: 768px) {
    height: 46px;

    svg {
      height: 46px;
    }
  }
`;

const HeaderNav = styled.nav`
  background-color: #b4d333;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 70px;
  transform: translateY(-100%);
  transition: transform 0.3s;

  @media only screen and (min-width: 1030px) {
    padding-top: 0;
    background: none;
    position: static;
    transform: none;
  }

  &.open {
    transform: translateY(0);
  }
`;

const HeaderList = styled.ul`
  position: relative;
  margin: 0;
  max-width: 1124px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

  list-style: none;

  @media only screen and (min-width: 360px) {
    padding-right: 25px;
    padding-left: 25px;
  }

  @media only screen and (min-width: 768px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media only screen and (max-width: 1029px) {
    min-height: 410px;
    height: 100%;
  }

  @media only screen and (min-width: 1030px) {
    overflow: visible;
    display: flex;
    margin-right: -22px;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    max-height: 100%;
    width: 100%;
  }

  li {
    position: relative;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
    margin: 0;
    padding: 0;
    vertical-align: baseline;

    &.no-border {
      border-bottom: none;
    }

    &.open-li {
      border-bottom: none;
    }

    @media only screen and (max-width: 1029px) {
      transition: opacity 0.3s, transform 0.3s;
    }

    @media only screen and (min-width: 1030px) {
      border: none;
      flex-shrink: 0;
    }
  }

  &.open-sub li:not(.open-li) {
    @media only screen and (max-width: 1029px) {
      opacity: 0;
    }
  }

  .language-switch {
    border-bottom: none;
  }
`;

const NavLink = styled.a`
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-decoration: none;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  font-family: Gotham, sans-serif;
  font-weight: 700;
  letter-spacing: -0.05em;
  font-size: 26px;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: calc(1.5625vw + 20px);
  }

  @media (min-width: 400px) {
    font-size: calc(1.6304347826vw + 19.4782608696px);
  }

  @media only screen and (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media only screen and (min-width: 1030px) {
    position: relative;
    padding: 10px 22px;
    transition: color 0.4s, width 0.4s;
    color: rgba(35, 38, 63, 0.7);
    font-size: 16px;
    font-family: Gilmer, sans-serif;
    border: 1px solid transparent;
  }

  @media (hover: hover) and (min-width: 1030px) {
    &:hover {
      color: rgba(180, 211, 51, 0.85);

      &:before {
        width: 22px;
        height: 2px;
        background-color: #b4d333;
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 0;
    height: 2px;
    transition: width 0.4s;
  }
`;

const RolloutIconMobile = styled.div<{ isOpen?: boolean }>`
  display: flex;

  svg {
    transition: transform 0.3s;
    transform: rotate(-270deg);

    path {
      fill: #fff;
    }

    .open-li & {
      transform: rotate(-90deg);
    }
  }

  @media only screen and (min-width: 1030px) {
    display: none;

    svg {
      path {
        fill: initial;
      }
    }
  }
`;

const RolloutIcon = styled.div<{ onlyMobile?: boolean }>`
  display: none;

  padding-left: 10px;

  @media only screen and (min-width: 1030px) {
    display: ${(props) => (props.onlyMobile ? 'none' : 'flex')};
  }

  @media only screen and (min-width: /*1280px*/1030px) {
    ${NavLink}:hover & svg {
      transform: rotate(180deg);
    }
  }
`;

const Rollout = styled.div<{ onlyMobile?: boolean; isOpen?: boolean }>`
  position: static;
  z-index: 1000;
  width: 370px;
  padding-top: 20px;
  padding-left: 25px;

  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  @media only screen and (min-width: /*1280px*/1030px) {
    display: none;
    position: absolute;
    top: 40px;
    left: -20px;
    background-color: #fff;
    z-index: 11;
    width: 370px;
    padding: 20px;

    ${NavLink}:hover + &, &:hover {
      display: ${(props) => (props.onlyMobile ? 'none' : 'block')};
    }
  }
`;

const RolloutLink = styled(NavLink)`
  font-size: 21px;

  @media only screen and (min-width: /*1280px*/1030px) {
    font-size: 16px;
  }
`;

const CloseLabel = styled.p`
  margin: 0;

  color: #fff;
  font-family: Gilmer, sans-serif;
  line-height: 1;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  margin-left: auto;
  z-index: 1;
  transition: transform 0.3s;
  transform: scale(0);
  transform-origin: right;

  .open + & {
    transform: scale(1);
  }

  @media (min-width: 400px) {
    font-size: calc(0.2272727273vw + 13.0909090909px);
  }

  @media only screen and (min-width: 1030px) {
    display: none;
  }

  @media (min-width: 1280px) {
    font-size: 16px;
  }
`;

const Hamburger = styled.div`
  margin-top: -14px;
  margin-right: -14px;
  margin-bottom: -14px;
  cursor: pointer;
  height: 48px;
  position: relative;
  transition: 0.4s cubic-bezier(0, 0, 0, 1);
  user-select: none;
  width: 48px;
  outline: none;

  @media only screen and (min-width: 1030px) {
    display: none;
  }

  > div {
    transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0.2s;
    transform: none;

    > div {
      background: rgb(0, 71, 142);
      height: 2px;
      left: 14px;
      position: absolute;
      width: 20px;
      top: 17px;
      transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0s;
      transform: none;
    }

    &:nth-child(2) {
      transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0s;
      opacity: 1;

      > div {
        transform: initial;
        top: 23px;
      }
    }

    &:nth-child(3) {
      > div {
        top: 29px;
      }
    }
  }

  .open + p + & {
    > div {
      > div {
        background: #fff;
      }
      &:nth-child(1) {
        transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0s;
        transform: translateY(6px);
        > div {
          transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0.2s;
          transform: rotate(45deg);
        }
      }
      &:nth-child(2) {
        transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0s;
        opacity: 0;
      }
      &:nth-child(3) {
        transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0s;
        transform: translateY(-6px);
        > div {
          transition: all 0.2s cubic-bezier(0, 0, 0, 1) 0.2s;
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

export interface NavItemContent {
  title: string;
  link: string;
  sub?: {
    onlyMobile: boolean;
    items: {
      title: string;
      link: string;
    }[];
  };
}

interface Props {
  onLanguageChange: () => void;
}

export default function Header({ onLanguageChange }: Props) {
  const { t, i18n } = useTranslation();
  const [isMobileView, setMobileView] = useState(true);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [openSub, setOpenSub] = useState<number>();
  const navUl = useRef<HTMLUListElement>(null);

  const content = useMemo(() => {
    if (i18n.language === 'cz') return navContentCz;
    else return navContentEn;
  }, [i18n.language]);

  useEffect(() => {
    const resizeListener = () => {
      const width = window.innerWidth;
      if (width < 1030) setMobileView(true);
      else setMobileView(false);
    };

    resizeListener();

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (!showMobileNav) setOpenSub(undefined);
    navUl.current?.childNodes.forEach((li) => {
      (li as any).style = '';
    });
  }, [showMobileNav]);

  const onHamburgerClick = () => {
    setShowMobileNav(!showMobileNav);
  };

  const onMobileSubClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    key: number
  ) => {
    let parentLi: any = (e.target as HTMLAnchorElement).parentNode;
    while (parentLi?.nodeName !== 'LI') {
      parentLi = parentLi?.parentNode;
    }
    if (openSub) {
      setOpenSub(undefined);
      parentLi.style = '';
    } else {
      setOpenSub(key);
      parentLi.style = `transform: translateY(-${parentLi.offsetTop}px);`;
    }
  };

  return (
    <HeaderEl>
      <HeaderContainer>
        <LogoImageContainer href='https://www.cncenter.cz'>
          <CNCLogo />
        </LogoImageContainer>
        <HeaderNav className={showMobileNav ? 'open' : ''}>
          <HeaderList ref={navUl} className={openSub ? 'open-sub' : ''}>
            {content.map((item, key) => (
              <li key={key} className={openSub === key ? 'open-li' : ''}>
                <NavLink
                  href={isMobileView && !!item.sub ? undefined : item.link}
                  onClick={
                    isMobileView && !!item.sub
                      ? (e) => {
                          onMobileSubClick(e, key);
                        }
                      : undefined
                  }
                >
                  {t(item.title)}
                  {item.sub && (
                    <>
                      <RolloutIconMobile isOpen={openSub === key}>
                        <ArrowRight />
                      </RolloutIconMobile>
                      <RolloutIcon onlyMobile={item.sub.onlyMobile}>
                        <ArrowDown />
                      </RolloutIcon>
                    </>
                  )}
                </NavLink>
                {item.sub && (
                  <Rollout
                    onlyMobile={item.sub.onlyMobile}
                    isOpen={openSub === key}
                  >
                    {item.sub.items.map((subItem, subKey) => (
                      <RolloutLink href={subItem.link} key={subKey}>
                        {t(subItem.title)}
                      </RolloutLink>
                    ))}
                  </Rollout>
                )}
              </li>
            ))}
            <li className={'language-switch'}>
              {i18n.language === 'cz' ? (
                <NavLink onClick={onLanguageChange}>EN</NavLink>
              ) : (
                <NavLink onClick={onLanguageChange}>CZ</NavLink>
              )}
            </li>
          </HeaderList>
        </HeaderNav>
        <CloseLabel onClick={onHamburgerClick}>{t('header.close')}</CloseLabel>
        <Hamburger role='button' tabIndex={0} onClick={onHamburgerClick}>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </Hamburger>
      </HeaderContainer>
    </HeaderEl>
  );
}
