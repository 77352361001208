const ArrowDown = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.10581 7.39419L9.5 4L8.65147 3.15147L5.25728 6.54566L1.86317 3.15155L1.01464 4.00008L5.25728 8.24272L5.25736 8.24264L6.10581 7.39419Z'
        fill='#616365'
      ></path>
    </svg>
  );
};

export default ArrowDown;
