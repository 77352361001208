import { styled } from 'styled-components';

export default function SuccessIcon() {
    return (
        <SuccessIconContainer>
            <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 29 29" fill="none">
                <circle cx="14.5" cy="14.5" r="14.5" fill="#B4D333"></circle>
                <path d="M12.364 18.193L21.556 9L22.971 10.414L12.364 21.021L6 14.657L7.414 13.243L12.364 18.193Z" fill="white"></path>
            </svg>
        </SuccessIconContainer>
    );
}

const SuccessIconContainer = styled.div`
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    margin-top: 30px;
    margin-bottom: 15px;
`;